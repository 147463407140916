export const getBellagioMapData = () => {
    return [
        {
            mapBg: require('@/assets/maps/bg-bellagio-route.jpg'),
            mapLine: 'BellagioPath',
            venueBg:  require('@/assets/maps/bellagio.png'),
            venue: 'Bellagio',
            distance: '2.0',
            singleLineVenue: true,
            threeLineVenue: false
        }
    ]
}
