<template>
  <div id="Bellagio">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>

<script>
import WhereWeAreMap from '@/components/global/WhereWeAreMap'
import { getBellagioMapData } from "@/data/sectionData/whereWeAreData/bellagioMapData"

export default {
  name: "CeasarsPalace",
  components: {
    WhereWeAreMap
  },
  data() {
    return {
      mapData: getBellagioMapData()
    }
  }
}
</script>

<style lang="scss scoped">

</style>